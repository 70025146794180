.icon {
  margin-left: 15px;
  margin-right: 15px;
}

.socialIcons {
  justify-content: center;
  display: flex;
  padding-top: 20px;
}

.blog {
  align-self: center;
  margin-left: 15px;
  margin-right: 15px;
}

.animated.delay-four {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
