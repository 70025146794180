.navigation {
  color: #999999;
  text-align: center;
  margin: 4vh 0vw;
}

.black {
  color: #111111;
}

.navLinks {
  color: #999999;
}

.navLinks::before {
  color: #111111;
  content: "";
  position: absolute;
  bottom: 8%;
  left: -1%;
  width: 102%;
  height: 3px;
  background: #eeeeee;
  transition: all ease-in-out 0.1s;
  z-index: -1;
}

.navLinks:hover::before {
  color: #111111;
  height: 90%;
  bottom: 5%;
}

.navLinks:hover {
  color: #333333;
  font-weight: 400;
}

.animated.delay-two {
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
