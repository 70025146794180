.profile {
  border-radius: 50%;
  height: 80px;
  text-align: center;
  opacity: 0.7;
}

.photoContainer {
  text-align: center;
}

.animated.delay-one {
  -webkit-animation-delay: 0s;
  animation-delay: 0;
}
