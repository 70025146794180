.container {
  width: 100%;
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 3vw;
  text-align: justify;
}

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b {
  color: #000000;
  font-weight: 400;
}

a {
  text-decoration: none;
  position: relative;
  display: inline-block;
  white-space: nowrap;
}

.blogLink::before {
  content: "";
  position: absolute;
  bottom: 10%;
  left: -1%;
  width: 102%;
  height: 3px;
  background: #eeeeee;
  transition: all ease-in-out 0.1s;
  z-index: -1;
}
.black {
  color: #111111;
}

.blogLink:hover::before {
  height: 90%;
  bottom: 10%;
}

.blogLink:hover {
  color: black;
  font-kerning: auto;
}

h1 {
  font-size: 18px;
  color: #333333;
}

.logo {
  width: 20px;
  display: inline;
  position: relative;
  filter: grayscale(100%);
  opacity: 0.5;
  vertical-align: middle;
}

.animated.delay-three {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
